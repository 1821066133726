import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { black_icons } from '../../icons';
// import settings_local from '../../Assets/countries.json'

const ShareModal = ({ show, onHide, onSubmit, selectedModel, selectedFabric, canvasState, settings, designerRef, canvasRef }) => {

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [priceRequest, setPriceRequest] = useState(false);
    const [countryOptions, setCountryOptions] = useState([]);
    const [designName, setDesignName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);


    useEffect(() => {

        const default_option = settings.countries.default;

        const options = Object.entries(settings.countries.items).map(([key, value]) => {
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                // Object.entries(value).find(entry => entry.value === default_option)
                return {
                    label: key,
                    options: Object.entries(value).map(([key, value]) => {
                        if (key === default_option) {
                            setSelectedCountry(key);
                        }
                        return {
                            value: key,
                            label: value,
                            type: 'optgroup'
                        }
                    })
                }

            }
            else {
                if (key === default_option) {
                    setSelectedCountry(key);
                }
                return {
                    value: key,
                    label: value,
                    type: 'option'
                };
            }

        });
        setCountryOptions(options);
        // console.log(settings_local.countries.default);
        // if (!settings_local.countries.default) {
        //     setSelectedCountry(settings_local.countries.find(o => o.value === "CA-AB").value);
        //     // setSelectedCountry(settings_local.countries.find(o => o.value === settings_local.countries.default).value);
        // }
    }, []);

    const handleCountryChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedCountry(selectedOption.value);
            // console.log(selectedOption);
        } else {
            setSelectedCountry(null);
        }
    };

    const handleDownloadPictures = (() => {
        if (designerRef.current) {
            designerRef.current.downloadPictures(designName || selectedModel.name + " - " + selectedFabric.name, [0]);
        }
    })

    const handlePhoneChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const DropdownIndicator = (
        props
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <black_icons.rollIcon />
            </components.DropdownIndicator>
        );
    };
    const Placeholder = (props) => (
        <components.Placeholder {...props}>
            Choose
        </components.Placeholder>
    );

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: '100%', // Set a specific width 
            minHeight: '40px', // Adjust minimum height if needed
        }),
        option: (provided, state) => {
            return {
                ...provided,
                backgroundColor: 'var(---ffffff-white)', // Change selected option background color state.isFocused
                color: state.isSelected ? 'var(--kb-500-0084cc)' : 'var(--k-900-303030)', //: 'var(--kb-500-0084cc)',           // Change selected option text color
                ':hover': {
                    backgroundColor: 'var(---ffffff-white)', // Change highlighted option background color
                    color: 'var(---e6ab00-hover-color)'                          // Change highlighted option background color
                },
                paddingLeft: state.data.type === 'option' ? '10px' : '15px',
            }
        },
        menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 15,
        }),
        // group: (provided) => ({
        //     ...provided,
        //     paddingLeft: '10px', // Add left padding to the optgroup
        //     // border: '1px solid #ccc', // Add a border (optional)
        //     // borderRadius: '5px', // Add rounded corners (optional)
        //     backgroundColor: 'var(--k-100-f0f0f0)',
        // }),
        groupHeading: (provided) => ({
            ...provided,
            paddingLeft: '10px',  // Add left padding to the label
            fontSize: '16px',     // Adjust font size (optional)
            fontWeight: 'bold',  // Make it bold (optional)
        }),
    };

    // const filterOption = (option, inputValue) => {
    //     if (!inputValue) return true; // Show all options if there's no input
    //     console.log(option.label, option.data.label)
    //     const lowerCaseInput = inputValue.toLowerCase();
    //     return (
    //         option.label.toLowerCase().includes(lowerCaseInput) || // Check option label
    //         option.data.label.toLowerCase().includes(lowerCaseInput) // Check optgroup label
    //     );
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);


        // Collect all form data
        const formData = {
            balloonName: event.target.elements.balloonName.value,
            name: event.target.elements.name.value, // Assuming 'name' is the name field's name
            email: event.target.elements.email.value,
            country: selectedCountry,
            phone: event.target.elements.phone.value,
            priceRequest: priceRequest,
        };

        // Validation
        let hasEmptyFields = false;
        for (const field in formData) {
            if (field === 'priceRequest' || field === 'phone') continue;
            if (!formData[field]) {
                hasEmptyFields = true;
                break;
            }
        }

        if (hasEmptyFields) {
            alert('Please fill in all required fields.');
        } else {

            // 1. Create a map of hex values to IDs
            const hexToIdMap = {};
            selectedFabric.colors.items.forEach(color => {
                hexToIdMap[color.hex] = color.id;
            });

            const nomexHexToIdMap = {};
            selectedFabric.nomex.items.forEach(color => {
                nomexHexToIdMap[color.hex] = color.id;
            });

            let nomexColor;

            // 2. Modify canvasState.envelopeGrid
            const modifiedEnvelopeGrid = canvasState.envelopeGrid.map((row, idx) => {
                if (idx === 0) {
                    nomexColor = nomexHexToIdMap[row[0]] || row[0];
                    return row.map(hexValue => nomexHexToIdMap[hexValue] || hexValue)
                } else {
                    return row.map(hexValue => hexToIdMap[hexValue] || hexValue) // Replace if found, else keep original

                }
            }
            );

            const pictures = designerRef.current.getPictures([0, 1, 2, 3, 4, 5, 6]).catch(error => {
                // Handle any errors that might occur during picture taking
                console.error("Error taking pictures:", error);
            });

            const envelope2D = canvasRef.current.getEnvelope2D();
            // console.log(await envelope2D);

            const data = {
                formData: formData,
                canvasState: {
                    ...canvasState,
                    envelopeGrid: modifiedEnvelopeGrid,
                    nomexColor: nomexColor
                },
                selectedModelID: selectedModel.id,
                selectedFabricID: selectedFabric.id,
                images: await pictures,
                envelope2D: await envelope2D
            };

            // Convert the data to a FormData object
            const formDataToSend = new FormData();
            for (const key in data) {
                formDataToSend.append(key, ['selectedModelID', 'selectedFabricID'].includes(key) ? data[key] : JSON.stringify(data[key]));
            }
            // console.log(data)

            // Send the POST request
            fetch(' https://kubicekballoons.com/en/designer-api/design/', {
                method: 'POST',
                body: formDataToSend
            })
                .then(response => response.json())
                .then(data => {
                    // Set the unique id as a parameter for the current page
                    const url = new URL(window.location);
                    url.searchParams.set('id', data.id);
                    window.history.pushState({}, '', url);
                    onHide(true);
                    setIsSubmitting(false);
                    setDesignName('');
                    setPhoneNumber('');
                    setPriceRequest(true);
                    setName('');
                    setEmail('');
                    setSelectedCountry(null);
                })
                .catch(error => console.error('Error:', error));
        }
    };

    return (
        <div className="mdl backdrop-blur-[30px]" style={{ display: show ? 'flex' : 'none' }}>
            <div className="mdl-dia">
                <form onSubmit={handleSubmit}>
                    <div className='mdl-row'>
                        <div className="form-group">
                            <label className="mdl-lbl" htmlFor="formBasicBalloonName">NAME YOUR BALLOON DESIGN FIRST</label>
                            <input type="text" className="form-control" id="formBasicBalloonName" placeholder={selectedModel && selectedFabric.name ? selectedModel.name + " - " + selectedFabric.name : "SELECT MODEL"} required name="balloonName" autoComplete='off' value={designName} onChange={(e) => setDesignName(e.target.value)} />
                        </div>
                        <button type="button" className="mdl-btn secondary align-end" onClick={() => { handleDownloadPictures(); onHide(false) }}>SIMPLE DOWNLOAD (.JPG)</button>
                    </div>
                    <div className='mdl-info'>
                        <div className='mdl-text'>GET COMPLETE BALLOON VISUAL</div>
                        <div className='mdl-sub-text'>Complete PDF hi-res layout with 6 views, colour palette and live link to editable model.</div>
                    </div>
                    <div className="mdl-row">
                        <div className="form-group">
                            <label className="mdl-lbl" htmlFor="formBasicName">YOUR NAME</label>
                            <input type="text" className="form-control" id="formBasicName" placeholder="Name" required name="name" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label className="mdl-lbl" htmlFor="formBasicEmail">E-MAIL</label>
                            <input type="email" className="form-control" id="formBasicEmail" placeholder="example@dot.com" required name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className="mdl-row">
                        <div className="form-group">
                            <label className="mdl-lbl" htmlFor="country">COUNTRY</label>
                            <Select
                                options={countryOptions}
                                isClearable={true}
                                onChange={handleCountryChange}
                                value={(() => {
                                    const group = countryOptions.find(o => o.options?.some(sub => sub.value === selectedCountry));
                                    if (group) {
                                        const selectedSubOption = group.options.find(sub => sub.value === selectedCountry);
                                        return {
                                            label: group.label + ' - ' + selectedSubOption.label,
                                            value: selectedSubOption.value,
                                            type: 'optgroup' // Important: Indicate this is a sub-option
                                        };
                                    } else if (countryOptions.some(o => o.value === selectedCountry)) {
                                        // Handle cases where selectedCountry is a top-level option
                                        const selectedOption = countryOptions.find(o => o.value === selectedCountry);
                                        return {
                                            label: selectedOption.label,
                                            value: selectedOption.value,
                                            type: 'option'
                                        };
                                    } else {
                                        return null; // Or handle the case where no match is found
                                    }
                                })()}
                                components={{ DropdownIndicator, Placeholder }}
                                styles={customStyles}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                inputId="country"
                                inputName="country"
                                required name='country'
                                menuPortalTarget={document.querySelector("body")}
                            // filterOption={filterOption}
                            />
                        </div>
                        <div className="form-group">
                            <label className="mdl-lbl" htmlFor="formBasicPhone">Phone Number (Optional)</label>
                            <input type="tel" className="form-control" id="formBasicPhone" placeholder={`Enter Phone Number`}
                                value={phoneNumber}
                                name="phone"
                                onChange={handlePhoneChange} />
                        </div>
                    </div>
                    <div className="mdl-sub-text">
                        <input type="checkbox" disabled={isSubmitting} checked={priceRequest} onChange={() => setPriceRequest(!priceRequest)} /> Request price
                    </div>
                    <div className="mdl-row">
                        <button type="submit" disabled={isSubmitting}
                            className={`mdl-btn primary ${isSubmitting ? 'submitting' : ''}`}
                        //className='submitting'
                        >
                            {isSubmitting ? <p>SUBMITTING...</p> : "GET COMPLETE DESIGN SENT TO MY E-MAIL"}
                        </button>
                        <button type="button" disabled={isSubmitting} className="mdl-btn secondary" data-bs-dismiss="modal" onClick={() => onHide(false)}>
                            CANCEL
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default ShareModal;
