import { useEffect, useRef } from "react";
import { Engine, Scene, WebGPUEngine } from "@babylonjs/core";
// import CustomLoadingScreen from "../Designer/KubicekLoadingScreen";

const SceneComponent = ({ sceneRef, antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, ...rest }) => {
  const reactCanvas = useRef(null);

  useEffect(() => {
    const { current: canvas } = reactCanvas;

    if (!canvas) return;

    let observer = null;
    let resize = null;

    // Function to create an engine based on browser support
    const createEngine = async () => {
      if (navigator.gpu && WebGPUEngine.IsSupportedAsync) {
        try {
          const isSupported = await WebGPUEngine.IsSupportedAsync;
          if (isSupported && false) { //TODO unblock webgpu
            console.log("Using WebGPU engine");
            const engine = new WebGPUEngine(canvas, antialias, engineOptions, adaptToDeviceRatio);
            // engine.
            await engine.initAsync();
            return engine;
          } else {
            console.warn("WebGPU support check failed unexpectedly.");
          }
        } catch (e) {
          console.warn("Error checking WebGPU support:", e);
        }
      }
      // Fallback to standard WebGL engine
      console.log("Using WebGL engine");
      return new Engine(canvas, antialias, engineOptions, adaptToDeviceRatio,
        // { loadingSreen: new CustomLoadingScreen() }
      );
    };
    // Await engine initialization
    const initializeScene = async () => {
      const engine = await createEngine();

      sceneRef.current = new Scene(engine, sceneOptions);


      engine.runRenderLoop(() => {
        if (typeof onRender === "function") onRender(sceneRef.current);
        sceneRef.current.getEngine().resize(true);
        sceneRef.current.render();
      });
      const resize = () => {
        sceneRef.current.getEngine().resize(true);
        // const myModel = sceneRef.current.getMeshByName("BalloonModel");
        // if (myModel) { // Only re-center if model is loaded
        //   const boundingBox = myModel.getHierarchyBoundingVectors();
        //   const center = boundingBox.min.add(boundingBox.max).scale(0.5);
        //   myModel.position = center.negate();
        // }
        // sceneRef.current.render();
      };


      // observer = new ResizeObserver(resize);
      // observer.observe(canvas);

      if (sceneRef.current.isReady()) {
        onSceneReady(sceneRef.current);
      } else {
        sceneRef.current.onReadyObservable.addOnce((scene) => onSceneReady(scene));
      }
    };

    initializeScene();

    return () => {
      if (sceneRef.current) {
        sceneRef.current.getEngine().dispose();
      }
      // if (observer) {
      //   observer.unobserve(canvas);
      //   observer.disconnect();
      // }
    };
  }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions,
  ]);

  return <canvas ref={reactCanvas} {...rest} />;
};

export default SceneComponent;