import React, { } from 'react';
import { black_icons } from '../../icons';

function ToolBar({ selectedTool, selectedColor, showContextToolBar, onShowContextToolBar, onToolChange }) {

  return (
    <div className="ToolBar" id="ToolBar" style={{ '--dynamic-fill-color': `${selectedColor.hex}` }}>

      <button className={`tool-button ${selectedTool === "Panel" ? "active" : ""}`} onClick={() => onToolChange("Panel")}>
        <black_icons.balloonPanel />Panel
      </button>
      <button className={`tool-button ${selectedTool === "Spiral" ? "active" : ""}`} onClick={() => onToolChange("Spiral")}>
        <black_icons.balloonDiagonalDown />Spiral
      </button>
      <button className={`tool-button ${selectedTool === "SpiralUp" ? "active" : ""}`} onClick={() => onToolChange("SpiralUp")}>
        <black_icons.balloonDiagonalUp />Spiral up
      </button>
      <button className={`tool-button ${selectedTool === "Row" ? "active" : ""}`} onClick={() => onToolChange("Row")}>
        <black_icons.balloonRow />Row
      </button>
      <button className={`tool-button ${selectedTool === "Gore" ? "active" : ""}`} onClick={() => onToolChange("Gore")}>
        <black_icons.balloonGore />Gore
      </button>
      <button className={`tool-button ${selectedTool === "Envelope" ? "active" : ""}`} onClick={() => onToolChange("Envelope")}>
        <black_icons.balloonEnvelope />Envelope
      </button>

      <button className={`tool-button fabric-button ${showContextToolBar && ["Panel", "Spiral", "SpiralUp", "Row", "Gore", "Envelope", "Swap", "Nomex"].includes(selectedTool) ? "active" : ""}`} disabled={["Paravent", "Graphic", "Text", "Scene", null].includes(selectedTool)} onClick={() => { onShowContextToolBar(!showContextToolBar); }} >
        <black_icons.colorPicker />Fabric
      </button>

      <button className={`tool-button ${selectedTool === "Swap" ? "active" : ""}`} onClick={() => { onToolChange("Swap") }}>
        <black_icons.switchColor />Swap
      </button>

      <button className={`tool-button ${selectedTool === "Nomex" ? "active" : ""}`} onClick={() => { onToolChange("Nomex") }}>
        <black_icons.balloonNomex />Nomex
      </button>
      <button className={`tool-button ${selectedTool === "Paravent" ? "active" : ""}`} disabled={false} onClick={() => onToolChange("Paravent")}>
        <black_icons.balloonParachute />Paravent
      </button>
      <button className={`tool-button ${selectedTool === "Graphic" ? "active" : ""}`} disabled={false} onClick={() => onToolChange("Graphic")}>
        <black_icons.addImg />Graphic
      </button>
      <button className={`tool-button ${selectedTool === "Text" ? "active" : ""}`} disabled={false} onClick={() => onToolChange("Text")}>
        <black_icons.addText />Text
      </button>
      {/* <button className={`tool-button ${selectedTool === "Scene" ? "active" : ""}`} disabled={false} onClick={() => onToolChange("Scene")}>
        <black_icons.background />Scene
      </button> */}

    </div>
  );
}

export default ToolBar;