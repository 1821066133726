import React from 'react';

const SharingDoneModal = ({ show, onHide }) => { // Updated props

    return (
        <div className="mdl backdrop-blur-[30px]" style={{ display: show ? 'flex' : 'none' }}>
            <div className="mdl-dia">
                <div className="mdl-tit">DONE!</div>
                <div className="mdl-alert">
                    <p>THANK YOU FOR USING KUBICEK BALLOONS DESIGNER</p>
                    <p>Check your e-mail to get your design package.</p>
                </div>
                <button className="mdl-btn primary" type="button" onClick={() => {
                    onHide(true); // Also close the modal
                }}>
                    CLOSE
                </button>
            </div>
        </div>
    );
};

export default SharingDoneModal